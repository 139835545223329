/* header */
.navbar-custom {
    background: linear-gradient(rgba(34, 34, 34, 0.95), rgba(34, 34, 34, 0.75));
    box-shadow: 0 2px 10px rgb(0 0 0 / 50%);
    z-index: 3;
}
.nav-menu {
}
.nav-menu li a {
  color: #ecd19a;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 0.9em;
  margin: 0 auto;
  padding: 5px 10px 5px 10px;
}
.nav-menu li a:hover {
  color: #ffffff;
}
/* header */

.page-banner {
  background-image: url("../src/assests/img/Banner.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.big-logo {
  height: auto;
  width: 50%;
  margin-bottom: 20px;
}

.banner_content {
  position: relative;
  max-height: 100%;
}
.banner_content h4 {
  color: #fff;
  font-size: 22px;
  line-height: 36px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin: 0 0 12px;
  text-shadow: 2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000;
}
.banner_content h4 a {
  color: #fff;
  display: inline-block;
}
.banner_content h4 a:hover {
  color: #000;
}
.banner_content h2 {
  font-size: 70px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2.8px;
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000;
}
.subscribe-div {
  background: url("../src/assests/img/bgofsubscribe.jpg");
  max-width: 100%;
  height: 500px;
}
.subscribe-div .title {
  font-weight: 500;
  font-family: cursive;
  text-align: center;
  text-transform: uppercase;
  padding: 4rem 0 2rem 0;
  font-size: 180%;
  color: #ecd19a;
  text-shadow: 0px 0px 6px #000000;
}
.subscribe-div .desc {
  margin: 1rem auto 0 auto;
  width: 60%;
  font-family: cursive;
  color: #71675b;
  font-size: 3vh;
  font-weight: 400;
  text-align: center;
  line-height: 1.6rem;
}
.subscribe-div .email {
  display: flex;
  justify-content: center;
}
.subscribe-div .btn-subscribe {
  background-color: #aaa;
  margin: 0 0 0 10px;
}
/* footer */
.pattern-dark {
  background-image: url("../src/assests/img/dark_leather.png");
}
.footer {
  font-family: Helvetica, Arial, sans-serif;
  color: #c4ae78 !important;
  padding-top: 4rem;
  padding-bottom: 2rem;
}
.footer nav {
  background: none;
}
.footer .footer-nav ul {
  list-style: none;
  padding: 0;
}
.footer .footer-nav ul li {
  display: inline-block;
  padding-right: 1rem;
}
.footer a {
  transition: all 0.33s;
  color: #c4ae78 !important;
  text-decoration: none;
}
.description {
  color: #c4ae78 !important;
}
.description a {
  color: #c4ae78 !important;
}
.footer .social-nav,
.footer .social-nav-header {
  font-size: 120%;
  text-align: center;
  margin-top: 0.5rem;
}
.footer .social-nav a,
.footer .social-nav-header a {
  padding: 0.5rem;
  border: none !important;
}
@media (min-width: 768px) {
  .footer .social-nav,
  .footer .social-nav-header {
    text-align: right;
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 992px) {
  .footer a {
    font-size: 100%;
  }
}
@media (min-width: 768px) {
  .footer a {
    font-size: 100%;
    background-color: transparent;
  }
  .footer .footer-nav {
    text-align: left;
  }
  .footer .footer-nav {
    text-align: center;
    font-size: 80%;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
